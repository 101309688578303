module.exports = {


	//api_demobase_path: 'https://api2-demo.joobster.at',
	api_demobase_path: '',
//	api_base_path: 'https://api2.joobster.at',
  api_base_path: '',
  api_admin_path: '/admin',
 //api_admin_path: '',
  api_service_path: 'https://service2.joobster.at',
  api_externallinkserver: 'https://viewer2.joobster.at',
  api_myclip: 'https://myclip.jlnk.at',

//  api_scrapper_path: 'https://admin-demo.joobster.at/campaign',
//  api_sendinblue: '',

	api_scrapper_path: 'https://admin.joobster.at/campaign',
	api_sendinblue: 'https://api.sendinblue.com',

  api_linkedintoken: '/red-linkedin/oauth/v2/accessToken',
  api_linkedintokenvalidity: "/red-linkedin/oauth/v2/introspectToken",
  tiktokclientkey: 'awx4oxuz7vrp79lt',
  tiktoksecretkey: 'DCcqVYvMIOoRdZ31rWdmOYNGUjVlyyfI',
  tiktokappid: '7265311798856779781',


	//api_base_path: '/admin',
	//	mainurl: 'https://api2-demo.joobster.at',
	//	mainurl: 'https://admin.joobster.at',
	//mainurl: '',
	//pwd_reset_route: 'https://api2.joobster.at',
	mandrillkey: 'SL0isrXw1DS6El50qzIGdw',
	sendinbluekey: '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',
	pexelsapikey: '563492ad6f917000010000019250514808294d9dbb579e2423ff3be0',
	pixabayapikey: '9437851-80225552de54ba70fc5502c86',
  giphyapikey: 'd04MnrgeNrHLCljepp67ybb7IAViwg0d',

	explainDashboard: ['', ''],
	explainClips: ['https://admin.joobster.at/cloud/joobster-0eut76ea/hzF83opa/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/hzF83opa/lo.jpg'],
	explainPortal: ['https://admin.joobster.at/cloud/joobster-0eut76ea/4NRfA7ra/hi.mp4','https://admin.joobster.at/cloud/joobster-0eut76ea/4NRfA7ra/lo.jpg'],
	explainViewer: ['https://admin.joobster.at/cloud/joobster-0eut76ea/4FmLYsDa/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/4FmLYsDa/lo.jpg'],
	explainMicro: ['https://admin.joobster.at/cloud/joobster-0eut76ea/zDfxHVva/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/zDfxHVva/lo.jpg'],
	explainStoryboard: ['https://admin.joobster.at/cloud/joobster-0eut76ea/iDqIk2ca/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/iDqIk2ca/lo.jpg'],
	explainSurvey: ['https://admin.joobster.at/cloud/joobster-0eut76ea/liP0KjEa/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/liP0KjEa/lo.jpg'],
	explainCloud: ['https://admin.joobster.at/cloud/joobster-0eut76ea/8yeyqTXa/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/8yeyqTXa/lo.jpg'],
	explainContactOrganizer: ['https://admin.joobster.at/cloud/joobster-0eut76ea/EHcIzvra/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/EHcIzvra/lo.jpg'],
	explainContactCompanies: ['https://admin.joobster.at/cloud/joobster-0eut76ea/NxjxPKYa/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/NxjxPKYa/lo.jpg'],
	explainContactFairs: ['https://admin.joobster.at/cloud/joobster-0eut76ea/9BRkuE5a/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/9BRkuE5a/lo.jpg'],
	explainContactCampaigns: ['https://admin.joobster.at/cloud/joobster-0eut76ea/tbZiUada/hi.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/tbZiUada/lo.jpg'],
	explainSubtitles: ['https://admin.joobster.at/cloud/joobster-0eut76ea/9Psn7KGa/hi16x9.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/9Psn7KGa/lo16x9.jpg'],
	explainAllesJob: ['https://admin.joobster.at/cloud/joobster-0eut76ea/02FYzdaa/hi16x9.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/02FYzdaa/lo16x9.jpg'],
	explainTemplateBuilder: ['https://admin.joobster.at/cloud/joobster-0eut76ea/0BHnNo0a/hi16x9.mp4', 'https://admin.joobster.at/cloud/joobster-0eut76ea/0BHnNo0a/lo16x9.jpg'],

  lastupdate: "13.06.2024",
  version: "1.0.10",


	//mailchimpkey: 'f68d249fe2cc7c7128b82e8597ec69ed-us16'

}
