import React, { useState } from 'react';

import {fork, actionChannel, put, take, call} from 'redux-saga/effects';
import Swal from 'sweetalert2'
import * as socialshareaction from './action';
import * as loginActions from '../Login/action';
import { LinkedInCallback, useLinkedIn } from 'react-linkedin-login-oauth2';


const settings = require('../../settings');
/**
 * Saga watchers
 */
export function *watchLinkedInCode() {
    let payload;
    const reqChannel = yield actionChannel(socialshareaction.GET_LINKEDIN_LOGIN_TOKEN)
    while (payload = yield take(reqChannel)) {
        yield fork(getLinkedInToken, payload);
    }
}


export function *watchLinkedInAccessTokenValidity() {
  let payload;
  const reqChannel = yield actionChannel(socialshareaction.GET_LINKEDIN_LOGIN_TOKEN_VALIDITY)
  while (payload = yield take(reqChannel)) {
      yield fork(getLinkedInTokenValidity, payload);
  }
}

export function *watchLinkedInGetConnectedOrganizations() {
  let payload;
  const reqChannel = yield actionChannel(socialshareaction.GET_LINKEDIN_ORGANIZATIONS)
  while (payload = yield take(reqChannel)) {
      yield fork(getLinkedInOrganizations, payload);
  }
}



/**
 * Auth Signin endpoint
 * @param {*} payload
 */
const getLinkedInData = async (payload) => {


  const body =   {
    grant_type: "authorization_code",
    'redirect_uri': payload.redirecturl,
    'client_id': payload.clientid,
    'client_secret': payload.clientsecret,
    'code' : payload.code,
    //'scope' : "r_liteprofile"
  }


   let awaitfetch =  await fetch( settings.api_linkedintoken + `?grant_type=authorization_code&client_id=77w5a6zleagjw1&client_secret=H5ij3nOOhp7I9ump&code=${payload.code}&redirect_uri=${payload.redirecturl}`,  {

    method: 'POST',

    headers: {
    'Content-Type': `application/x-www-form-urlencoded`,

    },
    //body: JSON.stringify(body)

  })

  return await awaitfetch.json();



};

 function *getLinkedInToken(action) {
    try {
        console.log('action is: ', action)
        const result = yield call(getLinkedInData, action.payload)

        console.log('code is success in socialshare saga: ', result)
        yield put({ type: socialshareaction.GET_LINKEDIN_LOGIN_TOKEN_REQUEST, payload:{code: action.code, token: result} } );

        let orgs = yield call(getConnectedLinkedInOrganizations, result.access_token)
        console.log('orgs are: ', orgs)
        const result2 = yield call(getLinkedInOrganizationData, {orgs: orgs.elements, accesstoken: result.access_token})
        console.log('code is success in socialshare saga: ', result2)

        yield put({ type: socialshareaction.GET_LINKEDIN_ORGANIZATIONS_REQUEST, payload:{organizations: result2.results} } );


/*
        if (result.status == 'ok') {
            yield put({ type: scrapperActions.GET_SERVER_DATA_SUCCESS, payload: result.companies });

        } else {
            console.log('Fetching error! in Scrapper SAGA')
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }
*/
    } catch(error) {
      console.log('Error in saga socialshare: ', error)
      /*

        console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
        */
    }

}


const getLinkedInDataValidity = async (payload) => {


  const body =   {
    client_id: payload.clientid,
    client_secret: payload.clientsecret,
    token : payload.token,
    //'scope' : "r_liteprofile"
  }
  var formBody = [];
  for (var property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

   let awaitfetch =  await fetch( settings.api_linkedintokenvalidity  //+ `?client_id=77w5a6zleagjw1&client_secret=H5ij3nOOhp7I9ump&token=${payload.token}`
    //+ `?client_id=${payload.clientid}&client_secret=${payload.clientsecret}&token=${payload.token}`
   ,  {
    method: 'POST',

    headers: {
    'Accept': 'application/json',
    'Content-Type': `application/x-www-form-urlencoded`,
    },

    body: formBody

  })

  return await awaitfetch.json();



};

function *getLinkedInTokenValidity(action) {
    try {
        console.log('action is: ', action)
        const result = yield call(getLinkedInDataValidity, action.payload)
        console.log('code is success in socialshare saga: ', result)



        //yield put({ type: socialshareaction.GET_LINKEDIN_LOGIN_TOKEN_REQUEST, payload:{code: action.code, token: result} } );

/*
        if (result.status == 'ok') {
            yield put({ type: scrapperActions.GET_SERVER_DATA_SUCCESS, payload: result.companies });

        } else {
            console.log('Fetching error! in Scrapper SAGA')
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }
*/
    } catch(error) {
      console.log('Error in saga socialshare: ', error)
      /*

        console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
        */
    }

}


async function getConnectedLinkedInOrganizations(at){

  let retfetch =  await fetch(  "/api-linkedin/rest/organizationAcls?q=roleAssignee", {
     method: "GET",
     headers: {
     Authorization: "Bearer " + at,
     "X-Restli-Protocol-Version" : "2.0.0",
     'Linkedin-Version' : "202306"

     }
   })
     return await retfetch.json()
 }


 async function getLinkedInOrganizationData( payload){



  let organizationids = payload.orgs.map(o => o.organization.split(":").pop()).join()

  let retfetch =  await fetch(settings.api_base_path +  `/api-linkedin/rest/organizations?ids=List(${organizationids})`, {
     method: "GET",
     headers: {
     Authorization: "Bearer " + payload.accesstoken,
     "X-Restli-Protocol-Version" : "2.0.0",
     'Linkedin-Version' : "202306"

     }
   })
     return await retfetch.json()
 }

 async function *getLinkedInOrganizations(action) {
  try {
      console.log('action is: ', action)
      let orgs = await  getConnectedLinkedInOrganizations(action.accesstoken)
      const result = yield call(getLinkedInOrganizationData, {orgs, accesstoken: action.accesstoken})
      console.log('code is success in socialshare saga: ', result)

      yield put({ type: socialshareaction.GET_LINKEDIN_ORGANIZATIONS_REQUEST, payload:{organizations: result.results} } );

/*
      if (result.status == 'ok') {
          yield put({ type: scrapperActions.GET_SERVER_DATA_SUCCESS, payload: result.companies });

      } else {
          console.log('Fetching error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }
*/
  } catch(error) {
    console.log('Error in saga socialshare with getLinkedInOrganizations: ', error)
    /*

      console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
      */
  }

}
